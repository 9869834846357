<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-08 18:45:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-08 18:46:05
-->
<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
        <a-row :gutter="2">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="searchData.name" placeholder="请输入商品名称" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="分类">
              <a-cascader
                :options="categoryList"
                :value="categorySelectData"
                style="width: 100%"
                :field-names="{ label: 'name', value: 'id', children: 'children' }"
                placeholder="请选择"
                @change="onCategoryChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="品牌">
              <a-select v-model="searchData.brandId" placeholder="请选择商品品牌" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in proBrandList" :key="index">
                  {{ item.ch_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                        <a-form-model-item label="审核状态">
                            <DictSelect field="productStatus" :value.sync="searchData.flowStatus" style="width: 100%" placeholder="审核状态"></DictSelect>
                        </a-form-model-item>
                    </a-col> -->
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                        <a-form-model-item label="上架状态">
                            <DictSelect field="groundingStatus" :value.sync="searchData.flagUp" style="width: 100%" placeholder="状态"></DictSelect>
                        </a-form-model-item>
                    </a-col> -->
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="库存">
              <a-input-number placeholder="开始" v-model="searchData.quantityStart" :min="1" :max="999999" />
              <a-input-number placeholder="结束" v-model="searchData.quantityEnd" :min="1" :max="999999" />
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button
          v-if="updateRepoNumBtn && selectedRows.length > 0"
          type="primary"
          @click="toHandler('updateRep')"
          >修改库存</a-button
        >
        <!-- <a-button v-if="isSee" type="primary" @click="toHandler('look')">查看</a-button> -->
        <!-- <a-button v-if="isSynchronizedInventory" type="default" @click="onSync()">同步库存</a-button> -->
      </div>
      <!-- 数据表格 -->
      <a-table
        :rowClassName="rowClassName"
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="name" slot-scope="text">
          <a-tooltip>
            <template slot="title"> {{ text }} </template> {{ text }}
          </a-tooltip>
        </span>
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>
        <span slot="flagUp" slot-scope="item">
          {{ item == 1 ? '上架' : item == 2 ? '下架' : '强制下架' }}
        </span>
        <span slot="productStatus" slot-scope="text">
          <div v-if="text == 1">未审核</div>
          <div v-if="text == 2">待审核</div>
          <div v-if="text == 3">审核通过</div>
          <div v-if="text == 4">已拒绝</div>
        </span>
        <span slot="price" slot-scope="item">{{ item.toFixed(2) }}</span>
        <span slot="action" slot-scope="text, row">
          <a @click="handleEdit(row)">编辑</a>
          <a-divider type="vertical" />
          <a @click="delByIds([row.id])">删除</a>
          <a-divider type="vertical" />
          <a @click="onCheck(row)">查看</a>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <ProductGoods-edit-modal ref="ProductGoodsEditModal" @reload="getData"></ProductGoods-edit-modal>

      <a-modal
        title="修改库存数量"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
        style="height: 150px"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-model-item label="实际库存">
            <a-input-number
              placeholder="请输入新的库存"
              v-model="realQuantity"
              :min="1"
              :max="999999"
              style="width: 100%"
            />

          </a-form-model-item>
          <a-form-model-item label="销售库存">
            <a-input-number
              placeholder="请输入新的销售库存"
              v-model="saleQuantity"
              :min="orderOccupy"
              :max="999999"
              style="width: 100%"
            />
          </a-form-model-item>
        </a-form-model>
        <div style="text-align: right">
          <a-button type="default" @click="visible = false">取消</a-button>
          <a-button type="danger" @click="onAudit('refuse')">确定</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { Repertorcolumns } from './components/colums.js'
import ProductGoodsEditModal from './components/ProductGoodsEditModal.vue'
import { delProductGoods, repositoryList, selectByIdProductGoods } from './api/ProductGoodsApi'
import { checkPermission } from '@/utils/permissions'
const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productGoods',
  components: {
    ProductGoodsEditModal,
  },
  data() {
    return {
      columns: Repertorcolumns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      // page: Object.assign({}, pageSource),
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: [],
      carinfo: [],
      categoryList: [],
      categoryValue: [],
      isSee: checkPermission('ordersend:repertory:see'),
      isSynchronizedInventory: checkPermission('ordersend:repertory:synchronizedInventory'),
      updateRepoNumBtn: checkPermission('ship:product:updateRepoNum'),
      categorySelectData: [],
      proBrandList: [],
      visible: false,
      confirmLoading: false,
      realQuantity: 0,
      saleQuantity: 0,
      orderOccupy:0
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          _this.realQuantity = selectedRows[0].realQuantity
          _this.saleQuantity = selectedRows[0].saleQuantity
          _this.orderOccupy = selectedRows[0].orderOccupyRep
        },
      }
    },
  },
  methods: {
    onCategoryChange(value) {
      console.log(value)
      this.categorySelectData = value
      var reg = new RegExp(',', 'g')
      this.searchData.categoryStr = value
        .map((i) => {
          return i
        })
        .toString()
        .replace(reg, '_')
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    // 审核
    onAudit(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      let query = {}
      if (name == 'sbm_audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 2,
        }
      } else if (name == 'audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 3,
        }
      } else if (name == 'refuse') {
        const data = {
          id: _this.selectedRowKeys[0],
          realNum: _this.realQuantity,
          saleNum: _this.saleQuantity,
        }
        _this.confirmLoading = true
        _this.axios.get('/api/product/system/goods/updateRepository', { params: data }).then((res) => {
          if (res.code == 200) {
            _this.$notification.success({ message: res.message })
            _this.visible = false
            _this.selectedRowKeys = []
            _this.selectedRows = []
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
          _this.confirmLoading = false
        })
      }
      //   _this.axios.post('/api/product/system/info/review', query).then((res) => {
      //     if (res.code == 200) {
      //       _this.$notification.success({ message: res.message })
      //     } else {
      //       _this.$notification.error({ message: res.message })
      //     }
      //   })
    },
    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(item, 'check')
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      repositoryList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // 修改是否上架
    onStatusChange(name) {
      const that = this
      let num = {}
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      if (name == 'up') {
        num = {
          id: that.selectedRows[0].id,
          flagUp: 1,
        }
      } else if (name == 'down') {
        num = {
          id: that.selectedRows[0].id,
          flagUp: 2,
        }
      }
      that.axios.post('/api/product/system/goods/editFlagUp', num).then((res) => {
        that.$notification.success({ message: res.message })
      })
    },
    onCarInfo(value) {
      this.searchData.carBrandId = value ? value[0] : ''
      this.searchData.carSeriesId = value ? value[1] : ''
      this.searchData.carModelId = value ? value[2] : ''
    },

     /**
     * 筛选查询
     */

     getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.categorySelectData = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    /**
     * 更改分页
     */
     changeTable(pagination) {
      this.page = pagination
      this.getData({}, pagination)
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onSync() {
      this.tableLoading = true
      this.axios.post('/api/product/system/goods/synchronizedInventor').then((res) => {
        if (res.code == 200) {
          this.getData()
          this.$notification.success({ message: res.message })
          this.tableLoading = false
        }
      })
    },
    // 编辑
    handleEdit(row) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(row, 'edit')
    },
    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          const res = await delProductGoods(_this.selectedRows[0].id)
          if (res.code === 200) {
            _this.$notification.success({ message: res.message })
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
        },
        onCancel() {},
      })
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ProductGoodsEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'updateRep':
          _this.realQuantity = _this.selectedRows[0].realQuantity
          _this.visible = true
          break
        case 'edit':
          _this.$refs.ProductGoodsEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductGoods(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'look':
          _this.$refs.ProductGoodsEditModal.setRowData(_this.selectedRows[0], 'look')
          break
      }
    },
  },
  created() {
    this.getData()
    this.axios.get('/api/product/category/categoryList?flagEnable=true').then((res) => {
      this.categoryList = res.body[0] ? res.body[0].children : []
    })
    this.axios.get('/api/product/system/brand/All/list').then((res) => {
      this.proBrandList = res.body
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>
